import { toRef as l, ref as c, watch as E, openBlock as _, createElementBlock as y } from "vue";
const D = "@vue-stripe/vue-stripe", M = "Vue.js composables & components for Stripe", U = "jofftiquez@gmail.com", q = "5.0.0", B = "module", V = [
  "dist"
], Y = "./dist/vue-stripe.umd.js", $ = "./dist/vue-stripe.es.js", W = {
  ".": {
    import: "./dist/vue-stripe.es.js",
    require: "./dist/vue-stripe.umd.js"
  }
}, X = {
  dev: "vite",
  build: "vite build",
  preview: "vite preview",
  "test:unit": "vitest",
  lint: "eslint . --ext .vue,.js,.jsx,.cjs,.mjs --fix --ignore-path .gitignore",
  "docs:dev": "vitepress dev docs",
  "docs:build": "vitepress build docs",
  "docs:preview": "vitepress preview docs"
}, z = {
  "@stripe/stripe-js": "^4.4.0",
  "vue-demi": "^0.14.10"
}, F = {
  "@rushstack/eslint-patch": "^1.3.3",
  "@vitejs/plugin-vue": "^4.5.2",
  "@vue/test-utils": "^2.4.3",
  eslint: "^8.49.0",
  "eslint-plugin-vue": "^9.17.0",
  jsdom: "^23.0.1",
  postcss: "^8.4.33",
  vite: "^5.4.3",
  vitepress: "^1.3.4",
  vitest: "^1.0.4",
  vue: "^3.3.11"
}, G = {
  "@vue/composition-api": "^1.7.2",
  vue: "^2.0.0 || >=3.0.0"
}, K = {
  "@vue/composition-api": {
    optional: !0
  }
}, H = "MIT", J = {
  name: D,
  description: M,
  author: U,
  version: q,
  type: B,
  files: V,
  main: Y,
  module: $,
  exports: W,
  scripts: X,
  dependencies: z,
  devDependencies: F,
  peerDependencies: G,
  peerDependenciesMeta: K,
  license: H
}, Q = J.version, Z = {
  name: "vue-stripe",
  version: Q,
  url: "https://vuestripe.com",
  partner_id: "pp_partner_IqtOXpBSuz0IE2"
}, j = "payment", w = "linkAuthentication", T = "expressCheckout", A = "address", x = "auBankAccount", L = "iban", ce = {
  __name: "AddressElement",
  props: {
    elements: {
      type: Object
    },
    options: {
      type: Object,
      default: () => ({
        mode: "shipping"
      })
    }
  },
  setup(o, { expose: e }) {
    e({
      getElement: u,
      updateElement: m,
      getValue: d
    });
    const t = o, n = l(t, "elements"), r = l(t, "options"), s = c(null), a = c(null);
    E(t, () => {
      i();
    });
    function i() {
      n.value && (a.value = n.value.create(A, r.value), a.value.mount(s.value));
    }
    async function u() {
      return n.value.getElement(A);
    }
    async function m(f) {
      return a.value.update(f);
    }
    async function d() {
      return a.value.getValue();
    }
    return (f, p) => (_(), y("div", {
      ref_key: "mountPoint",
      ref: s
    }, null, 512));
  }
}, le = {
  __name: "AuBankAccountElement",
  props: {
    elements: {
      type: Object,
      required: !0
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  emits: [
    "change",
    "ready",
    "focus",
    "blur",
    "escape"
  ],
  setup(o, { expose: e, emit: t }) {
    const n = t;
    e({
      getElement: d,
      updateElement: f
    });
    const r = o, s = l(r, "elements"), a = l(r, "options"), i = c(null), u = c(null);
    E(r, () => {
      m();
    });
    function m() {
      s.value && (u.value = s.value.create(x, a.value), u.value.mount(i.value), u.value.on("change", (p) => n("change", p)), u.value.on("ready", () => n("ready")), u.value.on("focus", () => n("focus")), u.value.on("blur", () => n("blur")), u.value.on("escape", () => n("escape")));
    }
    async function d() {
      return s.value.getElement(x);
    }
    async function f(p) {
      return u.value.update(p);
    }
    return (p, h) => (_(), y("div", {
      ref_key: "mountPoint",
      ref: i
    }, null, 512));
  }
}, pe = {
  __name: "ExpressCheckoutElement",
  props: {
    elements: {
      type: Object
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  emits: [
    "click",
    "confirm",
    "cancel",
    "shippingaddresschange",
    "shippingratechange"
  ],
  setup(o, { expose: e, emit: t }) {
    const n = t;
    e({
      getElement: d,
      updateElement: f,
      fetchUpdates: p
    });
    const r = o, s = l(r, "elements"), a = l(r, "options"), i = c(null), u = c(null);
    E(r, () => {
      m();
    });
    function m() {
      s.value && (u.value = s.value.create(T, a.value), u.value.mount(i.value), u.value.on("click", () => n("click")), u.value.on("confirm", () => n("confirm")), u.value.on("cancel", () => n("cancel")), u.value.on("shippingaddresschange", () => n("shippingaddresschange")), u.value.on("shippingratechange", () => n("shippingratechange")));
    }
    async function d() {
      return s.value.getElement(T);
    }
    async function f(h) {
      return u.value.update(h);
    }
    async function p() {
      return s.value.fetchUpdates();
    }
    return (h, v) => (_(), y("div", {
      ref_key: "mountPoint",
      ref: i
    }, null, 512));
  }
}, ve = {
  __name: "IbanElement",
  props: {
    elements: {
      type: Object,
      required: !0
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  emits: [
    "change",
    "ready",
    "focus",
    "blur",
    "escape"
  ],
  setup(o, { expose: e, emit: t }) {
    const n = t;
    e({
      getElement: d,
      updateElement: f
    });
    const r = o, s = l(r, "elements"), a = l(r, "options"), i = c(null), u = c(null);
    E(r, () => {
      m();
    });
    function m() {
      s.value && (u.value = s.value.create(L, a.value), u.value.mount(i.value), u.value.on("change", (p) => n("change", p)), u.value.on("ready", () => n("ready")), u.value.on("focus", () => n("focus")), u.value.on("blur", () => n("blur")), u.value.on("escape", () => n("escape")));
    }
    async function d() {
      return s.value.getElement(L);
    }
    async function f(p) {
      return u.value.update(p);
    }
    return (p, h) => (_(), y("div", {
      ref_key: "mountPoint",
      ref: i
    }, null, 512));
  }
}, me = {
  __name: "IssuingElement",
  props: {
    elements: {
      type: Object
    },
    options: {
      type: Object,
      default: () => ({})
    },
    elementType: {
      type: String,
      required: !0
    }
  },
  setup(o) {
    const e = o, t = l(e, "elements"), n = l(e, "options"), r = l(e, "elementType"), s = c(null), a = c(null);
    E(e, () => {
      i();
    });
    function i() {
      t.value && (a.value = t.value.create(r.value, n.value), a.value.mount(s.value));
    }
    return (u, m) => (_(), y("div", {
      ref_key: "mountPoint",
      ref: s
    }, null, 512));
  }
}, de = {
  __name: "LinkAuthenticationElement",
  props: {
    elements: {
      type: Object
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  setup(o, { expose: e }) {
    e({
      getElement: u
    });
    const t = o, n = l(t, "elements"), r = l(t, "options"), s = c(null), a = c(null);
    E(t, () => {
      i();
    });
    function i() {
      n.value && (a.value = n.value.create(w, r.value), a.value.mount(s.value));
    }
    async function u() {
      return n.value.getElement(w);
    }
    return (m, d) => (_(), y("div", {
      ref_key: "mountPoint",
      ref: s
    }, null, 512));
  }
}, fe = {
  __name: "PaymentElement",
  props: {
    elements: {
      type: Object
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  emits: [
    "change",
    "ready",
    "focus",
    "blur",
    "escape"
  ],
  setup(o, { expose: e, emit: t }) {
    const n = t;
    e({
      getElement: d,
      updateElement: f,
      fetchUpdates: p,
      collapse: h
    });
    const r = o, s = l(r, "elements"), a = l(r, "options"), i = c(null), u = c(null);
    E(r, () => {
      m();
    });
    function m() {
      s.value && (u.value = s.value.create(j, a.value), u.value.mount(i.value), u.value.on("change", (v) => n("change", v)), u.value.on("ready", (v) => n("ready", v)), u.value.on("focus", (v) => n("focus", v)), u.value.on("blur", (v) => n("blur", v)), u.value.on("escape", (v) => n("escape", v)));
    }
    async function d() {
      return s.value.getElement(j);
    }
    async function f(v) {
      return u.value.update(v);
    }
    async function p() {
      return s.value.fetchUpdates();
    }
    async function h() {
      return u.value.collapse();
    }
    return (v, ae) => (_(), y("div", {
      ref_key: "mountPoint",
      ref: i
    }, null, 512));
  }
};
var N = "https://js.stripe.com/v3", ee = /^https:\/\/js\.stripe\.com\/v3\/?(\?.*)?$/, O = "loadStripe.setLoadParameters was called but an existing Stripe.js script already exists in the document; existing script parameters will be used", ne = function() {
  for (var e = document.querySelectorAll('script[src^="'.concat(N, '"]')), t = 0; t < e.length; t++) {
    var n = e[t];
    if (ee.test(n.src))
      return n;
  }
  return null;
}, k = function(e) {
  var t = "", n = document.createElement("script");
  n.src = "".concat(N).concat(t);
  var r = document.head || document.body;
  if (!r)
    throw new Error("Expected document.body not to be null. Stripe.js requires a <body> element.");
  return r.appendChild(n), n;
}, te = function(e, t) {
  !e || !e._registerWrapper || e._registerWrapper({
    name: "stripe-js",
    version: "4.8.0",
    startTime: t
  });
}, g = null, b = null, P = null, re = function(e) {
  return function() {
    e(new Error("Failed to load Stripe.js"));
  };
}, ue = function(e, t) {
  return function() {
    window.Stripe ? e(window.Stripe) : t(new Error("Stripe.js not available"));
  };
}, se = function(e) {
  return g !== null ? g : (g = new Promise(function(t, n) {
    if (typeof window > "u" || typeof document > "u") {
      t(null);
      return;
    }
    if (window.Stripe && e && console.warn(O), window.Stripe) {
      t(window.Stripe);
      return;
    }
    try {
      var r = ne();
      if (r && e)
        console.warn(O);
      else if (!r)
        r = k(e);
      else if (r && P !== null && b !== null) {
        var s;
        r.removeEventListener("load", P), r.removeEventListener("error", b), (s = r.parentNode) === null || s === void 0 || s.removeChild(r), r = k(e);
      }
      P = ue(t, n), b = re(n), r.addEventListener("load", P), r.addEventListener("error", b);
    } catch (a) {
      n(a);
      return;
    }
  }), g.catch(function(t) {
    return g = null, Promise.reject(t);
  }));
}, oe = function(e, t, n) {
  if (e === null)
    return null;
  var r = e.apply(void 0, t);
  return te(r, n), r;
}, S, C = !1, R = function() {
  return S || (S = se(null).catch(function(e) {
    return S = null, Promise.reject(e);
  }), S);
};
Promise.resolve().then(function() {
  return R();
}).catch(function(o) {
  C || console.warn(o);
});
var I = function() {
  for (var e = arguments.length, t = new Array(e), n = 0; n < e; n++)
    t[n] = arguments[n];
  C = !0;
  var r = Date.now();
  return R().then(function(s) {
    return oe(s, t, r);
  });
};
function Ee(o, e) {
  const t = c(null), n = c(null);
  async function r() {
    var i;
    e != null && e.disableAdvancedFraudDetection && I.setLoadParameters({ advancedFraudSignals: !1 });
    const a = {
      stripeAccount: e == null ? void 0 : e.stripeAccount,
      apiVersion: e == null ? void 0 : e.apiVersion,
      locale: e == null ? void 0 : e.locale
    };
    t.value = await I(o, a), (i = t.value) == null || i.registerAppInfo(Z);
  }
  async function s(a, i) {
    var u;
    t.value || await r(), n.value = (u = t.value) == null ? void 0 : u.elements({
      ...i,
      clientSecret: a
    });
  }
  return r(), {
    stripe: t,
    elements: n,
    initializeElements: s
  };
}
export {
  ce as AddressElement,
  le as AuBankAccountElement,
  pe as ExpressCheckoutElement,
  ve as IbanElement,
  me as IssuingElement,
  de as LinkAuthenticationElement,
  fe as PaymentElement,
  Ee as useStripe
};
