import '@mdi/font/css/materialdesignicons.css';
import { createApp } from 'vue';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as Sentry from "@sentry/vue";
import App from './app/App.vue';

const app = createApp(App);

const vuetify = createVuetify({
    autoImport: true
});
app.use(vuetify);

Sentry.init({
    app,
    dsn: "https://9ac49aece2574f68bdcd70dedb88083d@o181848.ingest.us.sentry.io/1272093",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/portal\.osttolney\.org/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

let mountIn = document.querySelector("#donation-box");
app.mount(mountIn);
