<script setup>
import { useStripe, PaymentElement } from '@vue-stripe/vue-stripe';
import { computed, nextTick, ref } from 'vue';

const moneyRegex = new RegExp(/^\d+(\.\d{1,2})?$/, 'g');

const rules = {
    required: value => !!value || 'Field is required',
    email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
    },
    money: value => {
        if (isNaN(value)) {
            return 'Must be a number';
        }
        if (value < 1) {
            return 'Must be a number greater than 1';
        }
        if (value > 5000) {
            return 'This form does not accept payments greater than $5,000';
        }
        if (value.match(moneyRegex) === null) {
            return 'This form only accepts donation amounts in dollars and cents';
        }
        return true;
    }
};

const FEE_MULTIPLIER = 0.0226;

const showForm = ref(true);

const { stripe, elements, initializeElements } = useStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
initializeElements(undefined, { mode: 'payment', currency: 'usd', amount: 100, appearance: { theme: 'flat', labels: 'floating', }, setupFutureUsage: 'off_session', });
const paymentElement = ref(null);

const includeFee = ref(true);
const donationFieldValue = ref('');
const donationAmountCents = computed(() => {
    if (donationFieldValue.value) {
        return Math.floor(parseInt(donationFieldValue.value) * 100);
    } else {
        return 0;
    }
});
const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
const feeAmount = computed(() => {
    const inputValue = donationAmountCents.value;
    const addThirtyCents = (inputValue >= 100000) ? 0 : 30;
    return Math.floor(inputValue * FEE_MULTIPLIER + addThirtyCents);
});
const donationTotal = computed(() => {
    const total = donationAmountCents.value + (includeFee.value ? feeAmount.value : 0);
    nextTick(() => {
        elements.value?.update({ amount: Math.max(total, 100) });
    });
    return total;
});

const switchLabel = computed(() => {
    return `I'd like to help cover processing costs - add ${USDollar.format(feeAmount.value / 100)} to my transaction`;
});

const buttonLabel = computed(() => {
    return `Donate ${USDollar.format(donationTotal.value / 100)}`;
});

const paymentFilledIn = ref(false);
function onPaymentChange(evt) {
    paymentFilledIn.value = evt.complete;
}

const formValid = ref(false);
const submitEnabled = computed(() => {
    return paymentFilledIn.value && formValid.value;
});

const selectedCountry = ref('us');
const zipName = computed(() => {
    if (selectedCountry.value === 'us') {
        return 'ZIP';
    }
    return 'Postal Code';
});

// Fallback categories in case we could not load the categories
const categories = ref(['General Donation', 'My Bill', 'Other - **please email treasurer@osttolney.org to explain**']);

fetch(`${import.meta.env.VITE_APP_URL}/api/donate`)
    .then(result => result.json())
    .then(data => {
        categories.value = data.categories;
    });

const form = ref(null);
const loading = ref(false);

function handleSubmitError(message) {
    alert(message);
}

async function submit(evt) {
    try {
        const formData = new FormData(evt.target);
        loading.value = true;

        const {error: submitError} = await elements.value.submit();
        if (submitError) {
            handleSubmitError(submitError.message);
            return;
        }

        const {error, confirmationToken} = await stripe.value.createConfirmationToken({
            elements: elements.value,
            params: {
                payment_method_data: {
                    billing_details: {
                        name: formData.get('name'),
                        email: formData.get('email'),
                        phone: formData.get('phone'),
                        address: {
                            line1: formData.get('address1'),
                            line2: formData.get('address2'),
                            city: formData.get('city'),
                            state: formData.get('state'),
                            postal_code: formData.get('zip'),
                            country: formData.get('country'),
                        }
                    }
                }
            },
        });
        if (error) {
            handleSubmitError(error.message);
            return;
        }

        if (includeFee.value) {
            formData.append('cover-costs', 'on');
        }
        formData.append('stripeToken', confirmationToken.id);

        const res = await fetch(`${import.meta.env.VITE_APP_URL}/api/donate`, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: formData,
        });

        if (res.ok) {
            showForm.value = false;
        } else {
            // TODO: better error handling
            alert(await res.text());
        }
    } finally {
        loading.value = false;
    }
}

const cardTitle = computed(() => {
    if (showForm.value) {
        return 'Donate to OSTT';
    }
    return 'Thank you for your support!';
});

function reload() {
    // TODO: Is there a good way to stay on the page and do this?
    //       Or is it better to do a full cleanup and reload anyway?
    window.location.reload();
}

</script>

<template>
    <v-card :title="cardTitle" :color="showForm ? undefined : 'green'" :variant="showForm ? undefined : 'tonal'">
        <v-form v-if="showForm" v-model="formValid" :readonly="loading" ref="form" @submit.prevent="submit">
            <v-container>
                <v-row>
                    <v-col cols="12" md="4" sm="6">
                        <v-text-field
                            name="amount"
                            density="comfortable"
                            label="Donation Amount (USD)"
                            prefix="$"
                            v-model="donationFieldValue"
                            :rules="[rules.required, rules.money]"
                            hide-details="auto"
                        />
                    </v-col>
                    <v-col cols="12" md="8" sm="6">
                        <v-select
                            name="donationType"
                            density="comfortable"
                            label="Donation Purpose"
                            item-title="description"
                            item-value="cc_identifier"
                            :items="categories"
                            :rules="[rules.required]"
                            hide-details="auto"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-divider />
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12">
                        <v-text-field
                            name="name"
                            density="comfortable"
                            label="Full Name"
                            :rules="[rules.required]"
                            hide-details="auto"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" xl="6" sm="12">
                        <v-text-field
                            name="email"
                            density="comfortable"
                            label="Email Address"
                            required
                            autocomplete="email"
                            :rules="[rules.required, rules.email]"
                            hide-details="auto"
                        />
                    </v-col>
                    <v-col cols="12" xl="6" sm="12">
                        <v-text-field
                            name="phone"
                            density="comfortable"
                            label="Phone Number"
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12">
                        <v-text-field
                            name="address1"
                            density="comfortable"
                            label="Address Line 1"
                            hint="If you wish to receive a year-end tax receipt, please provide your full address. <em>Note that Zip Code is required for credit card validation, even if you do not provide the rest of your address.</em>"
                            persistent-hint
                        >
                            <template v-slot:message="{ message }"><span v-html="message"></span></template>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-text-field
                            name="address2"
                            density="comfortable"
                            label="Address Line 2"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" lg="4" sm="6">
                        <v-text-field
                            name="city"
                            density="comfortable"
                            label="City"
                            autocomplete="address-level2"
                        />
                    </v-col>
                    <v-col cols="12" lg="2" sm="3">
                        <v-text-field
                            name="state"
                            density="comfortable"
                            label="State"
                            autocomplete="address-level1"
                        />
                    </v-col>
                    <v-col cols="12" lg="2" sm="3">
                        <v-text-field
                            name="zip"
                            density="comfortable"
                            :label="zipName"
                            autocomplete="postal-code"
                            :rules="[rules.required]"
                            hide-details="auto"
                        />
                    </v-col>
                    <v-col lg="4" sm="12">
                        <v-select
                            name="country"
                            density="comfortable"
                            label="Country"
                            item-title="name"
                            item-value="code"
                            :items="[{code: 'us', name: 'United States of America'}, {code: 'ca', name: 'Canada'}]"
                            v-model="selectedCountry"
                            :rules="[rules.required]"
                            hide-details="auto"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-divider />
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12">
                        <PaymentElement
                            ref="paymentElement"
                            :elements="elements"
                            :options="{ fields: { billingDetails: 'never' } }"
                            @change="onPaymentChange"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-divider />
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12">
                        <v-switch
                            density="comfortable"
                            :label="switchLabel"
                            color="primary"
                            v-model="includeFee"
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="12">
                        <v-btn
                            color="primary"
                            size="large"
                            :disabled="!submitEnabled"
                            @click="form.requestSubmit()"
                        >{{ buttonLabel }}</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>
        <template v-else>
            <v-card-text style="text-align: center;">
                <svg width="84px" height="84px" viewBox="0 0 84 84" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <circle class="border" cx="42" cy="42" r="40" stroke-linecap="round" stroke-width="4" stroke="#000" fill="none"></circle>
                    <path class="checkmark" stroke-linecap="round" stroke-linejoin="round" d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338" stroke-width="4" stroke="#000" fill="none"></path>
                </svg>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="reload()">Donate Again</v-btn>
            </v-card-actions>
        </template>
    </v-card>
</template>
